import {
	ANIM_DBG,
	CLASSES_CORE, CORE_ENGINES, EVENTS
} from './core/constants';
import 'waypoints/lib/noframework.waypoints.min.js';

import {
	$S,
	i18n
} from './dpitUtilsPublic';
import BrowserSupport from '../../../../includes/assets/js/modules/BrowserSupport';

import {
	getDpUrlParam,
	decodeStepParamAdvancedVal,
	asyncWait,
} from '../../../../includes/assets/js/modules/dpitUtilsInc';
import TourVisitCounter from './TourVisitCounter';
import DpitHooks from '../../../../includes/assets/js/modules/DpitHooks';


export default class Dpit {

	constructor(mainCfg, tourCfg) {
		if (mainCfg.dpDebugEn) {
			console.log('[dpit] INIT Dpit');
			console.log('[dpit] INIT jQuery v: ' + jQuery.fn.jquery);
		}
		this.tourCfg = tourCfg;
		this.mainCfg = mainCfg;
		this.tourId = null;
		if (this.tourCfg) {
			this.tourCfg.text_styles = { ...this.mainCfg.text_styles, ...this.tourCfg.text_styles };
			this.tourId = this.tourCfg.tourId;
		}

		this.currentUrl = new URL(window.location);
		this.hookOptions = {
			tourId: this.tourId,
			currentUrl: this.currentUrl
		};
		this.startStep = this.getDpUrlParam('dp_qp_step');
		if (this.shouldInitialize()) {
			this.initMainContainers();
			if (this.tourCfg) {
				this.initTriggers();
				this.initCore();
			}
		}
	}

	shouldInitialize() {
		const shouldInit = DpitHooks.applyFilters('shouldInit', true, this.hookOptions);
		const diviBuilderMode = this.currentUrl.searchParams.get('et_fb') === '1';
		const oxygenBuilderMode = this.currentUrl.searchParams.get('ct_builder') === 'true';
		return !(diviBuilderMode || oxygenBuilderMode) && BrowserSupport.isSupported() && shouldInit;
	}

	getDpUrlParam(id, defVal = null) {
		return getDpUrlParam(id, this.mainCfg.queryParamsDefs, this.currentUrl, defVal);
	}

	initTriggers() {
		this.triggers = [];
		this.tourCfg.triggers.forEach((triggerCfg, idx) => {
			this.triggers.push({
				idx: idx,
				config: triggerCfg,
				jElement: (triggerCfg.selector === 'window') ? $(window) : $S(triggerCfg.selector),
				isInRun: false,
				wasRunInThisSession: false
			});
		});
		this.activeTriggerIdx = 0;
	}

	initMainContainers() {
		this.wrapEl = document.createElement('div');
		this.wrapEl.classList.add(CLASSES_CORE.wrap);
		document.body.appendChild(this.wrapEl);
	}


	isOnLoadTrigger(trigger) {
		return trigger.config.on == 'load' || this.shouldAlwaysRunOnLoad();
	}

	isOnLoadDisabled() {
		return this.mainCfg.disableOnLoad;
	}

	isVisitCountAffectedTrigger(trigger) {
		return !['click', 'mousedown', 'dblclick', 'submit'].includes(trigger.config.on);
	}

	isAllowFutureVisitsCheckboxEnabled(trigger) {
		return trigger.config.allow_future_visits_checkbox;
	}


	isTourInitDisabledDueVisitCount(trigger) {
		if (!this.isVisitCountAffectedTrigger(trigger)) return false;
		if (this.isAllowFutureVisitsCheckboxEnabled(trigger) && !TourVisitCounter.isVisitEnabled(this.tourId)) return true;
		if (trigger.config.first_user_visit_only) {
			var visitedCnt = TourVisitCounter.getVisitCount(
				this.mainCfg,
				this.tourId,
				this.tourCfg.loggedInVisitCnt,
				this.tourCfg.visitsLsKeyVersion,
				trigger.config.visit_count_mode === 'browser'
			);
			let limit = trigger.config.first_n_user_visit;
			if (this.mainCfg.dpDebugEn) console.log('[dpit] VISIT COUNT: ', visitedCnt, 'LIMIT:', limit);
			return (visitedCnt >= limit);
		}
		return false;
	}

	shouldAlwaysRunOnLoad() {
		return this.getDpUrlParam('dp_qp_run_always_on_load') === '1';
	}

	shouldRunAtCurrentDevice() {
		return true;
	}

	initTourWithSelectedTrigger(trigger) {
		let run = true;
		if (trigger.config.allow_just_for_logged_in_users && this.mainCfg.currentUserId == 0) {
			run = false;
		}
		if (trigger.config?.disable_for_roles?.length && this.mainCfg.currentUserRoles && this.mainCfg?.currentUserRoles?.length) {
			for (const val of trigger.config.disable_for_roles) {
				if (this.mainCfg.currentUserRoles.includes(val)) {
					run = false;
					break;
				}
			}
		}

		if (this.isTourInitDisabledDueVisitCount(trigger)) {
			run = false;
		}

		if (!this.shouldRunAtCurrentDevice(trigger)) run = false;

		if (run || this.shouldAlwaysRunOnLoad()) {
			if (this.isOnLoadTrigger(trigger) && !this.isOnLoadDisabled(trigger)) {

				this.startWithAnimation(trigger, this.startStep);
			} else {
				if (!trigger.config.once_per_session_only || !trigger.wasRunInThisSession) {
					setTimeout(() => {
						this.activateTrigger(trigger);
					}, 300);
				}
			}
		}
	}

	initTour() {
		this.triggers.forEach((trigger) => {
			this.initTourWithSelectedTrigger(trigger);
		});
	}

	activateTrigger(trigger) {
		if (!this.isOnLoadTrigger(trigger)) {
			if (trigger.config.on === 'inviewport') {
				if (trigger.jElement.length) {
					this.waypointTrigger = new Waypoint({
						element: trigger.jElement[0],
						handler: () => this.startTour(trigger),
						offset: trigger.config['in_view_port_offset'] || 0
					});
				}
			} else {
				trigger.jElement.on(trigger.config.on, (event) => {
					let start = true;
					if (trigger.config.specific_key_pressed && event) {
						if (trigger.config.on === 'keydown' ||
							trigger.config.on === 'keypress' ||
							trigger.config.on === 'keyup') {
							start = trigger.config.specific_key_pressed == event.keyCode;
						}
					}
					if (start) {
						if (event) {
							event.preventDefault();
							//event.stopPropagation();
						}
						this.startWithAnimation(trigger, this.startStep);
					}
				});
			}
		}
	}

	async createCore() {
		const coreModuleName = (this.tourCfg.theme === 'sticky') ? CORE_ENGINES.sticky : CORE_ENGINES.base;
		const module = await import(/* webpackChunkName: "[request]" */ `./core/${coreModuleName}`);
		const core = new module.default(this.wrapEl, this.mainCfg, this.tourCfg);
		core.setOptions(this.getIntroCoreOptions());
		return Promise.resolve(core);
	}

	afterIntroCoreInit() {

		// for future
	}

	initCore() {
		this.steps = this.initPreprocessSteps();
		if (this.mainCfg.dpDebugEn) console.log('[dpit] INIT Steps config:', this.steps);
		if (this.steps && this.isStepsEnough2Start()) {
			this.initCoreEngine();
		}
	}

	async initCoreEngine() {

		this.core = await this.createCore();
		this.afterIntroCoreInit();
		this.initTour();
	}

	initPreprocessSteps() {
		let steps = $.parseJSON(this.tourCfg.steps);
		if (steps) {
			steps = steps.map((step) => {
				const preprocessedStep = {};
				Object.keys(step).forEach(paramName => {
					preprocessedStep[paramName] = decodeStepParamAdvancedVal(step[paramName], paramName, this.mainCfg.stepDefinitions, true, true);
				});
				return preprocessedStep;
			});
		}
		return steps;
	}

	getIntroCoreOptions() {
		return {
			disableApi: true,
			steps: this.steps,
			scrollTo: 'all',
			enable_interaction: '0',
			showStepNumbers: this.tourCfg.properties.show_step_numbers,
			showProgress: this.tourCfg.properties.show_progress_bar,
			showBullets: this.tourCfg.properties.show_bullet_navigation,
			labeling: this.tourCfg.labeling,
			nextLabel: this.tourCfg.labeling.nextLabel || i18n('next', this.mainCfg.i18n),
			prevLabel: this.tourCfg.labeling.prevLabel || i18n('back', this.mainCfg.i18n),
			skipLabel: this.tourCfg.labeling.skipLabel || i18n('skip', this.mainCfg.i18n),
			doneLabel: this.tourCfg.labeling.doneLabel || i18n('done', this.mainCfg.i18n),
			skipForFutureVisitsLabel: this.tourCfg.labeling.skipForFutureVisitsLabel,
			exitOnOverlayClick: this.tourCfg.properties.exitOnOverlayClick,
			hide_previous_step_button: this.tourCfg.properties.hide_previous_step_button,
			hide_skip_button: this.tourCfg.properties.hide_skip_button,
			disable_navigation_by_bullets: this.tourCfg.properties.disable_navigation_by_bullets,
			max_tooltip_width: this.tourCfg.properties.max_tooltip_width,
			skip_absent_ref_el: this.tourCfg.properties.skip_absent_ref_el,
			customTextStylesEn: !$.isEmptyObject(this.mainCfg.text_styles),
			scrollSpeed: this.tourCfg.properties.scrollSpeed,
		};
	}

	isStepsEnough2Start() {
		return this.steps.length > 0;
	}

	getApiCallbacks() {
		return {};
	}

	setAsRunningTour(themeOverride, accentColorOverride) {
		if (!this.core.isThisInRun()) {
			this.core.setAsRunningTour(this.getApiCallbacks());
			this.core.subscribeEvent(EVENTS.ended, this.onExitWrap);
			this.core.initThemeOptions(themeOverride, accentColorOverride);
		}
	}

	resetAsRunningTour() {
		this.core.resetAsRunningTour();
		this.core.unsubscribeEvent(EVENTS.ended, this.onExitWrap);
	}

	async startWithAnimation(trigger, step = 0, forceStart = false, backward = false, themeOverride = null, accentColorOverride = null) {

		if (!this.tourCfg.properties.disableStartAnimation) {
			if (this.isStepsEnough2Start() && !this.core.isInRun()) {
				this.setAsRunningTour();
				this.core.startFullScreenAnimation(backward);

				if (!ANIM_DBG) {
					if (this.tourCfg.properties.loading_delay > 0) await asyncWait(this.tourCfg.properties.loading_delay);
					this.core.stopFullScreenAnimationAfterLoopEnd(() => {
						this.startTour(trigger, step, forceStart, true, themeOverride, accentColorOverride);
					}, /*this.steps?.length > 0*/ false);
				}
			}
		} else {
			this.core.initBGOverlayLayer();
			this.core.showBackgroundOverlay();
			if (this.tourCfg.properties.loading_delay > 0) await asyncWait(this.tourCfg.properties.loading_delay);
			this.startTour(trigger, step, forceStart, false, themeOverride, accentColorOverride);
		}

	}

	canStartTour(trigger, forceStart = false, disableIsInRunCheck = false) {
		return this.isStepsEnough2Start()
			&& (disableIsInRunCheck || !this.core.isInRun())
			&& (forceStart || !trigger.config.once_per_session_only || !trigger.wasRunInThisSession)
			&& !this.core.isPaused();
	}

	processUserVisit(step) {
		if (!step) {
			TourVisitCounter.processVisit(this.mainCfg, this.tourId, this.tourCfg.visitsLsKeyVersion);
		}
	}


	startTour(trigger, step = 0, forceStart = false, disableIsInRunCheck = false, themeOverride = null, accentColorOverride = null) {

		if (this.canStartTour(trigger, forceStart, disableIsInRunCheck)) {
			this.setAsRunningTour(themeOverride, accentColorOverride);
			trigger.isInRun = true;
			if (this.mainCfg.dpDebugEn) console.log('[dpit] Starting tour: ', this.tourCfg.tourName + ' (' + this.tourId + ')');
			this.processUserVisit();
			this.core.startCore(
				this.isInitializedBackward(),
				step,
				this.isVisitCountAffectedTrigger(trigger),
				this.isAllowFutureVisitsCheckboxEnabled(trigger)
			);

			trigger.wasRunInThisSession = true;
			this.activeTriggerIdx = trigger.idx;
			return true;
		}
		return false;
	}

	isInitializedBackward() {
		return false;
	}

	onExitWrap = (args) => {
		this.onExit(args.reason);
	};

	onExit(reason) {
		if (this.mainCfg.dpDebugEn) console.log('[dpit] Exiting tour: ' + this.tourId + ' - reason: ' + reason);
		this.triggers[this.activeTriggerIdx].isInRun = false;
		this.resetAsRunningTour();
	}
}