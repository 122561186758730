
const KEY_4_VISITED_DATA = 'dpit.tours_visits';
const KEY_4_DISABLED_TOURS = 'dpit.tours_disabled';
const KEY_4_VISITED_DATA_LEGACY = 'dp_intro_tours.visited_urls';

export default class TourVisitCounter {

	static _getVersionBasedValue(lsData, lsKeyVersion, tourId, defVal = 0) {
		if (lsData[tourId]) {
			const val = lsData[tourId][lsKeyVersion];
			return val !== undefined && val !== null ? val : defVal;
		}
		return defVal;
	}

	static _processVisitToLS(mainCfg, tourId, visitsLsKeyVersion) {
		localStorage.removeItem(KEY_4_VISITED_DATA_LEGACY);
		const visitedData = TourVisitCounter._getDataFromLS(KEY_4_VISITED_DATA);
		let visitsThisTour = this._getVersionBasedValue(visitedData, visitsLsKeyVersion, tourId, 0);
		visitedData[tourId] = {};
		visitedData[tourId][visitsLsKeyVersion] = visitsThisTour + 1;

		if (mainCfg.dpDebugEn) console.log('[dpit] UPDATE LS VISIT COUNT:', `tourId:${tourId}`, visitsThisTour + 1);
		localStorage.setItem(KEY_4_VISITED_DATA, JSON.stringify(visitedData));
	}

	static _getDataFromLS(key, defVal = {}) {
		const dataJson = localStorage.getItem(key);
		let data = defVal;
		if (dataJson && isNaN(dataJson)) {
			try {
				data = JSON.parse(dataJson) || defVal;
			} catch (exc) {
				console.error(exc);
			}
		}
		return data;
	}

	static _getVisitCountFromLS(tourId, visitsLsKeyVersion, visitedObj = null) {
		if (!visitedObj) {
			visitedObj = TourVisitCounter._getDataFromLS(KEY_4_VISITED_DATA);
		}
		let visitedThisTour = 0;
		if (visitedObj[tourId]) {
			visitedThisTour = parseInt(visitedObj[tourId][visitsLsKeyVersion]);
		}
		if (isNaN(visitedThisTour)) {
			visitedThisTour = 0;
		}
		return visitedThisTour;
	}

	static _getVisitCountFromServer(loggedInVisitCnt) {
		const visitedThisTour = parseInt(loggedInVisitCnt);
		return isNaN(visitedThisTour) ? 0 : visitedThisTour;
	}

	static _processActionOnServer(mainCfg, actionName, tourId) {
		$.ajax({
			url: mainCfg.siteUrl + '/wp-json/dpintrotours/v1/trigger-action',
			type: 'POST',
			data: {
				actionName: actionName,
				tourId: tourId,
			},
			beforeSend: function (xhr) {
				xhr.setRequestHeader('X-WP-Nonce', mainCfg.nonces.wp_rest);
			},
			success: function (response) {
				console.log(actionName + ' action was successful', response);
			},
			error: function (_, __, error) {
				// Handle error
				console.error(actionName + ' action emitted error:', error);
			}
		});
	}

	static _disableFutureVisitsByLS(mainCfg, tourId, enable = false) {
		const disabledTours = TourVisitCounter._getDataFromLS(KEY_4_DISABLED_TOURS, []);
		if (!enable && !disabledTours.includes(tourId)) {
			disabledTours.push(tourId);
			if (mainCfg.dpDebugEn) console.log('[dpit] UPDATE LS DISABLED ON LOAD TOURS:', `tourId:${tourId}`);
			localStorage.setItem(KEY_4_DISABLED_TOURS, JSON.stringify(disabledTours));
		} else if (enable && disabledTours.includes(tourId)) {
			disabledTours.splice(disabledTours.indexOf(tourId), 1);
			if (mainCfg.dpDebugEn) console.log('[dpit] UPDATE LS ENABLED ON LOAD TOURS:', `tourId:${tourId}`);
			localStorage.setItem(KEY_4_DISABLED_TOURS, JSON.stringify(disabledTours));
		}
	}

	static isVisitEnabled(tourId) {
		const disabledTours = TourVisitCounter._getDataFromLS(KEY_4_DISABLED_TOURS, []);
		return !disabledTours.includes(tourId);
	}

	static disableFutureVisits(mainCfg, tourId, enable = false) {
		/*if (mainCfg.currentUserId != 0) {
			// server based data
			TourVisitCounter._processActionOnServer(mainCfg, 'disable_future_visits', tourId);
		}*/
		TourVisitCounter._disableFutureVisitsByLS(mainCfg, tourId, enable);
	}

	static processVisit(mainCfg, tourId, visitsLsKeyVersion) {
		if (mainCfg.currentUserId != 0) {
			// server based data
			TourVisitCounter._processActionOnServer(mainCfg, 'process_visit', tourId);
		}
		// local storage based data
		TourVisitCounter._processVisitToLS(mainCfg, tourId, visitsLsKeyVersion);
	}

	static getVisitCount(mainCfg, tourId, loggedInVisitCnt, visitsLsKeyVersion, forceInBrowser = false) {
		if (mainCfg.currentUserId != 0 && !forceInBrowser) {
			// server based data
			return TourVisitCounter._getVisitCountFromServer(loggedInVisitCnt);
		} else {
			// local storage based data
			return TourVisitCounter._getVisitCountFromLS(tourId, visitsLsKeyVersion);
		}
	}

}
